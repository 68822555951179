import { graphql } from 'gatsby';
import React from 'react';
import Header from '../components/header';
import Layout from '../components/layout';
import SEO from '../components/seo';

import image1 from '../images/carte.png';
import image2 from '../images/enfant.jpeg';

const Enfants = ({ data }) => {
  const prereq = <><b style={{ color:"green" }}>Aucun prérequis équestre n'est nécessaire, ON NE MONTE PAS LE CHEVAL.</b></>;

  return (
    <Layout>
      <SEO title="Enfants" />
      <Header metadata={data.site.siteMetadata} />
      <img width={450} height={600} src={image1} alt=""/>
      <br/>
      {prereq}
      <br/><br/>
      Les enfants rencontrent parfois des difficultés et l'accompagnement vers une solution  les aide à avancer à grands pas.<br/>
      Des séances personnelles peuvent être envisagées, celles-ci durent entre 45 minutes et 1 heure et sont adaptées à l'âge de l'enfant et à sa problématique, par exemple:<br/><br/>
      * Atteindre un objectif, <br/>
      * Régler un problème relationnel, <br/>
      * Gestion des émotions, <br/>
      * Agressivité - violence, <br/>
      * Difficultés à vivre la séparation des parents, <br/>
      * Famille recomposée: problèmes relationnels. <br/><br/>

      Pour enfant à partir de 6 ans.
      <br/><br/>
    <img width={450} height={600} src={image2} alt=""/>
    </Layout>
  );
};

export default Enfants;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
